:root {
  --acft-green: var(#B3CF40);
  --acft-dark-greem: var(#7B8D31);
  --acft-background: var(hsla(153, 18%, 10%, 1));
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
  font-family: myFirstFont;
  src: url(/src/Assets/Fonts/Bebas.ttf);
}
@font-face {
  font-family: mySecondFont;
  src: url(/src/Assets/Fonts/Satoshi.ttf);
}
@font-face {
  font-family: myThirdFont;
  src: url(/src/Assets/Fonts/Outfit.ttf);
}

* {
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;  
}


/* Wrapper */
.App {
  display: flex;
  justify-content: center;
}
.wrapper {
  max-width: 1440px;
  
}

/* Body */

body {
  background-color: #151E1A;
  display: flex;
  flex-direction: column;
}

/* Header */

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 80px;
}

.logo {
  width: 70px;
  height: 40px;
}

.button-green > button {
  width: 122px;
  height: 44px;
  background-color: #7B8D31;
  border-radius: 8px;
  color: #FFFFFF;

  font-family: MySecondFont;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.55px;
}

/*Main Top section*/

.top-section {
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
  min-height: 1100px;
  
  /* Green Radient Blur */
  background-image: 
    url("../src/Assets/Black.png"),
    radial-gradient(circle 500px at 75% 50%, rgba(102, 112, 65, 0.5), rgba(102, 112, 65, 0));

  display: flex;
  flex-direction: row;
  padding: 0 80px;
  align-items: center;
}

.top-section-left {
  display: flex;
  flex-direction: column;
  max-width: 576px;
  gap: 32px;
}
.top-section-left-title {  
  font-family: myFirstFont;
  font-size: 104px;
  /*font-weight: 700;*/
  line-height: 104px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  text-transform: uppercase;
}

.top-section-left-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}


.top-section-left-content > div {
  font-family: myThirdFont;
  font-size: 28px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #B3CF40;
}

.top-section-left-content > p {
  font-family: myThirdFont;
  font-size: 19px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #AEC0B4;
}

.top-section-left-up {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.top-section-left-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.top-section-right {
  /*background: radial-gradient(circle, rgba(102, 112, 65, 1) 0%, rgba(102, 112, 65, 0) 70%);
  filter: blur(8px);*/
}

/* Buttons*/ 

.download-button {
  width: 172px;
  height: 62px;
  padding: 12px 20px;  
  border-radius: 11px;
  background-color: #31382E;  

  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.apple-btn {
  width: 172px;
  height: 62px;
}
.google-btn {
  width: 169px;
  height: 62px;
}

/* Container */

.container {
  display: grid;
  grid-template-columns: 0.9fr;
  justify-content: space-evenly;
  row-gap: 32px;
}

.element-one {
  background-color: #293126;
  border-radius: 31px;
  padding: 50px 50px 0 80px;
  display: flex;
  flex-direction: row;
  gap: 125px;
  align-items: center;
}
.element-two {
  background-color: #293126;
  border-radius: 31px;
  padding: 50px 50px 0 80px;
  display: flex;
  flex-direction: row-reverse;
  gap: 125px;
  align-items: center;
  
}
.element-three {
  background-color: #293126;
  border-radius: 31px;  
  display: flex;
  flex-direction: row;  
  align-items: center;
  overflow: hidden;
}

.element-five {
  background-color: #293126;
  border-radius: 31px;
  padding: 120px 0 120px 95px;
  display: flex;
  flex-direction: row;
  gap: 115px;
  align-items: center;
}

.element-one-left {
  margin-bottom: -4px;
}
.element-one-right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 50px;
}

.element-one-right-up {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.element-one-right-green {
  font-family: myFirstFont;
  font-size: 23px;
  /*font-weight: 700;*/
  line-height: 24px;  
  color: #B3CF40;
  
}

.element-one-right-main {
  font-family: myFirstFont;
  font-size: 64px;
  /*font-weight: 700;*/
  line-height: 72px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.element-one-right-gray {
  font-family: myThirdFont;
  font-size: 17px;  
  line-height: 24px;
  color: #AEC0B4;
}


/* Card */

.element-four {
  display: grid;
  gap: 30px;
  justify-items: center;
  grid-template-columns: 0.5fr 0.5fr;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 50px 70px 50px;
  border-radius: 31px;
  background-color: #293126;
  gap: 40px;
}

.card-img {
  width: 218.5px;
  height: 441.5px;
}

.card-text {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.card-text-up {
  display: flex  ;
  flex-direction: column;
  gap: 16px;
}

.card-text-up-green {
  font-family: myFirstFont;
  font-size: 23px;  
  line-height: 24px;
  color: #B3CF40;
}

.card-text-up-main {
  font-family: myFirstFont;
  font-size: 48px;  
  line-height: 56px;
  letter-spacing: -0.01em;  
  color: #FFFFFF;
}

.card-text-down {
  font-family: myThirdFont;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #AEC0B4;
}


/*Bottom section*/

.bottom-section {
  position: relative;
  display: grid;
  grid-template-columns: 0.9fr;
  justify-content: center;
  justify-items: center;
  padding-top: 250px;
  padding-bottom: 80px;    
  grid-gap: 60px;
}

.bottom-section::before {
  content: ""; /* Required for the pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle 500px at 75% 50%, rgba(102, 112, 65, 0.5), rgba(102, 112, 65, 0));
  filter: blur(10px);
  
}

.bottom-section-img {
  background-image: url("../src/Assets/back2.png");
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-height: 800px;
  width: 100%;  
  background-repeat: no-repeat;
  background-size: cover; /* Ensure the image covers the container properly */
  background-position: center; /* Center the image within the container */
  position: relative; /* Ensure the image stays on top of the gradient */
  z-index: 1;
}

.bottom-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 37px;
}
.bottom-section-content-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.bottom-section-content-logo {
  width: 122px;
  height: 77px;
}

.bottom-section-content-main {
  font-family: myFirstFont;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  text-transform: uppercase;
}

.bottom-section-content-button{
  display: flex;
  flex-direction: row;
  gap: 8px;
}



/* Responsiveness */

@media (max-width: 1024px) {
  .element-one, 
  .element-two,
  .element-three,  
  .element-five {
    display: flex;
    flex-direction: column;
    padding: 50px 50px 50px 80px;
  }
  .element-three {
    gap: 125px;
  }

  .element-four {
    grid-template-columns: 1fr;
  }

  .top-section {
    min-height: 800px;    
    margin-bottom: 60px;
    background-image: url("../src/Assets/Black.png"), radial-gradient(circle 400px at 75% 50%, rgba(102, 112, 65, 0.5), rgba(102, 112, 65, 0));
  }
  .bottom-section {
    padding-top: 60px;
  }
  .bottom-section::before {
    background-image: radial-gradient(circle 400px at 75% 50%, rgba(102, 112, 65, 0.5), rgba(102, 112, 65, 0));
  }
}

@media (max-width: 658px) {
  .element-one, 
  .element-two,
  .element-three,  
  .element-five {
    padding: 20px;
  }

  .card {
    padding: 20px;
  }

  element-one-left. > img {
    width: 320px;
  }
  
  header {
    padding: 20px;
  }

  .top-section {
    padding: 20px;
  }

 .top-section-left-buttons {
  flex-direction: row;
 }

 .bottom-section-content-button {
  flex-direction: column;
 }
}

@media (max-width: 574px) { 
  .top-section {
    background-image: none;
    min-height: max-content;
  }
  .bottom-section-img {
    background-image: none;
  }
  .bottom-section::before {
    background-image: none;
  }
  .bottom-section-img {
    min-height: auto;
  }
  div.element-one-left > img {
    width: 320px;
  }
  .element-one, 
  .element-two,
  .element-three,  
  .element-five {
    gap: 60px;
  }
  div.element-three > div.element-one-left {
    display: none;
  }
}

@media (max-width: 425px) { 
 .top-section-left-title {
  font-size: 50px;
  line-height: 54px;
 }
 .top-section-left-content > div {
  font-size: 20px;
 }
 .element-one-right-main {
  font-size: 32px;
  line-height: 30px;
 }

 .container {
  grid-template-columns: 0.95fr;
 }

}

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Added to allow wrapping on smaller screens */
  justify-content: center;
  align-items: center;       
  padding: 20px; /* Added padding for better spacing */    
}
.footer-element {
  font-family: myThirdFont;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #FFFCFC;
}

.element {
  position: relative;
  padding: 20px;
  margin: 10px 0;    
}

.element::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  width: 4px; 
  height: 4px; 
  background-color: #B3CF40;
  border-radius: 50%;
}

.element:not(:last-child)::after {
  /* Keep the dot for all elements except the last */
  content: "";
}

.element:last-child::after {
  /* Remove the dot for the last element */
  content: none;
}

.textScreens {
  font-family: myThirdFont;
  font-weight: 400;
  line-height: 20px;
  margin-top: 20px;
}